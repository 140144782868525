import { Check } from './types/index';
import { useEffect, useState } from 'react';

const POLL_INTERVAL_MS = 10000;
const TIMER_INTERVAL_MS = 1000;

export default function Homepage() {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingError, setLoadingError] = useState<boolean>(false);
    const [checks, setChecks] = useState<Check[]>([]);
    const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

    useEffect(() => {
        const pollInterval = setInterval(loadData, POLL_INTERVAL_MS);
        loadData();

        return () => clearInterval(pollInterval);
    }, []);

    useEffect(() => {
        document.body.style.background =
            loadingError || lastUpdated < Date.now() - 60 * 1000
                ? '#FFE88D'
                : '#FFF';
    }, [loadingError, lastUpdated]);

    function loadData() {
        fetch('Default.aspx/GetStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                setChecks(data.d);
                setLastUpdated(Date.now());
                setLoading(false);
                setLoadingError(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingError(true);
            });
    }

    const [
        justDown,
        down2Min,
        down10Min,
        down30Min,
        downToday,
        downLonger,
        upEntries,
    ] = processChecks(checks);

    return (
        <>
            {loading ? (
                <h1>Loading data...</h1>
            ) : (
                <>
                    <Timer lastUpdated={lastUpdated} />
                    <Totals downChecks={[justDown, down2Min, down10Min, down30Min, downToday, downLonger]} upChecks={[upEntries]} />
                    {justDown.length > 0 && (
                        <div className='just-down'>
                            <h3>Just gone down  ({justDown.length})</h3>
                            <div className='container'>
                                <FormatGroup group={justDown} />
                            </div>
                        </div>
                    )}
                    {down2Min.length > 0 && (
                        <div className='down-2-min'>
                            <h3>Down for at least 2 minutes ({down2Min.length})</h3>
                            <div className='container'>
                                <FormatGroup group={down2Min} />
                            </div>
                        </div>
                    )}
                    {down10Min.length > 0 && (
                        <div className='down-10-min'>
                            <h3>Down for at least 10 minutes ({down10Min.length})</h3>
                            <div className='container'>
                                <FormatGroup group={down10Min} />
                            </div>
                        </div>
                    )}
                    {down30Min.length > 0 && (
                        <div className='down-30-min'>
                            <h3>Down for at least 30 minutes ({down30Min.length})</h3>
                            <div className='container'>
                                <FormatGroup group={down30Min} />
                            </div>
                        </div>
                    )}
                    {downToday.length > 0 && (
                        <div className='down-today'>
                            <h3>Down in the last 24 hours ({downToday.length})</h3>
                            <div className='container'>
                                <FormatGroup group={downToday} />
                            </div>
                        </div>
                    )}
                    {downLonger.length > 0 && (
                        <div className='down-longer'>
                            <h3>Down longer than a day ({downLonger.length})</h3>
                            <div className='container'>
                                <FormatGroup group={downLonger} />
                            </div>
                        </div>
                    )}
                    {upEntries.length > 0 && (
                        <div className='up-entries'>
                            <h3>Up Services ({upEntries.length})</h3>
                            <div className='container'>
                                <FormatGroup group={upEntries} isUp />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

function processChecks(checks: Check[]): Check[][] {
    const justDown: Check[] = [];
    const down2Min: Check[] = [];
    const down10Min: Check[] = [];
    const down30Min: Check[] = [];
    const downToday: Check[] = [];
    const downLonger: Check[] = [];
    const upEntries: Check[] = [];
    for (const d of checks) {
        if (d.current && d.current.t) {
            let previousTime = Number(d.current.t);
            if (isNaN(previousTime)) {
                previousTime = new Date().getTime();
            };
            const downtime = new Date().getTime() - previousTime;
            const minutes = Math.floor(downtime / 60000);
            if (minutes < 2) {
                justDown.push(d);
            } else if (minutes < 10) {
                down2Min.push(d);
            } else if (minutes < 30) {
                down10Min.push(d);
            } else if (minutes < 60) {
                down30Min.push(d);
            } else if (minutes < 1440) {
                downToday.push(d);
            } else {
                downLonger.push(d);
            }
        } else {
            upEntries.push(d);
        }
    }
    return [
        justDown,
        down2Min,
        down10Min,
        down30Min,
        downToday,
        downLonger,
        upEntries,
    ];
}

function FormatGroup({ group, isUp = false }: { group: Check[]; isUp?: boolean }) {
    function dhms(t: number): string {
        const cd = 24 * 60 * 60 * 1000,
            ch = 60 * 60 * 1000,
            cm = 60 * 1000;

        const d = Math.floor(t / cd);
        const h = Math.floor((t - d * cd) / ch);
        const m = Math.floor((t - d * cd - h * ch) / cm);
        const s = Math.floor((t - d * cd - h * ch - m * cm) / 1000);

        const hStr = `0${h}`.slice(-2);
        const mStr = `0${m}`.slice(-2);
        const sStr = `0${s}`.slice(-2);

        return [d, hStr, mStr, sStr].join(':');
    }

    return (
        <>
            {group.map((d, idx) => (
                <div key={idx} className={`status ${isUp ? 'up' : 'down'}`}>
                    {isUp ? (
                        d.label
                    ) : (
                        <>
                                <span className="status-title">{d.label}</span>
                            <br />
                            Down for: {dhms(new Date().getTime() - Number(d.current?.t || new Date().getTime()))}
                            <br />
                            {d.current?.message || 'unknown Error'}
                        </>
                    )}
                </div>
            ))}
        </>
    );
}

const Timer = ({ lastUpdated }: { lastUpdated: number }) => {
    const [timePassed, setTimePassed] = useState<number>(0);

    useEffect(() => {
        setTimePassed(Math.floor((Date.now() - lastUpdated) / 1000));
        const timerInterval = setInterval(() => {
            setTimePassed((prev) => prev + 1);
        }, TIMER_INTERVAL_MS);

        return () => clearInterval(timerInterval);
    }, [lastUpdated]);

    return <div>Last updated {timePassed} seconds ago</div>;
};

const Totals = ({ downChecks, upChecks }: {downChecks: Check[][], upChecks: Check[][]}) => {
    const totalDown = downChecks.reduce((total: number, checks: Check[]) => total + checks.length, 0);
    const totalUp = upChecks.reduce((total: number, checks: Check[]) => total + checks.length, 0);

    return (
        <div className="totals">
            <p className="total"><span className="total-title">Total Down:</span> {totalDown}</p>
            <p className="total"><span className="total-title">Total Up:</span> {totalUp}</p>
        </div>
    )
}